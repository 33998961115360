<template>
  <div>
    <NavTitle class="mb16" title="统计总数据" :showBack="false">
      <template #right>
        <div class="flex items-center">
          <Dropdown :value="curDrop" :data="dropList" @change="dropChange"></Dropdown>
          <Dropdown class="ml12" :value="curDrop" :data="dropList" @change="dropChange"></Dropdown>
        </div>
      </template>
    </NavTitle>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Dropdown from '@/components/Dropdown'
export default {
  name: 'StatisticsTotal',
  components: {
    NavTitle,
    Dropdown
  },
  data() {
    return {
      curDrop: 0,
      dropList: [
        {value:0, text: '全部渠道'},
        {value:1, text: '华为'},
        {value:2, text: '小米'},
      ]
    }
  },
  methods: {
    dropChange(e) {
      console.log('drop改变', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-dropdown {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #505E5C;
  height: 32px;
  padding-left: 16px;
  background-color: #F2F3F5;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  .icon-down {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: var(--theme-color);
    margin-left: 16px;
    img {
      width: 14px;
      height: 14px;
    }
  }
}
</style>